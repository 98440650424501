import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  // Badge,
  Drawer,
  List,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  ListItemButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Soul_pizza_logo.png";

import { THEME_CONSTANT } from "../../lib/themeConstants";
import { MenuIcon, LogoImage } from "../../components";
import "./header.css";
import { useNavigate } from "react-router";
// import { useLazyGetCartQuery } from "../../services/cart.service";
// import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAppDispatch} from "../../redux/hooks";
import {
  resetCartState,
  // setCartCountState,
  // setCartListState,
} from "../../redux/slices/cart";
import { isLoggedIn } from "../../lib/helperFunctions";
import { resetProductState } from "../../redux/slices/products";
import { resetCategoryState } from "../../redux/slices/category";

const Header = ({ isThemeBackground = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [cartCount, setCartCount] = useState(0);

  // const { cartCountState } = useAppSelector((state) => ({
  //   cartCountState: state.cart.cartCountState,
  // }));

  // const [getCart] = useLazyGetCartQuery();
  
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  useEffect(() => {
    if (isLoggedIn()) {
      // fetchCart();
    }
  }, []);

  // useEffect(() => {
  //   setCartCount(Number(cartCountState));
  // }, [cartCountState]);

  const getNavLinks = (isMobile) => {
    const navLinks = [
      { label: t("navigation:ORDER_NOW"), href: "/categories" },
      { label: "Contact Us", href: "#contact-section" }, // Scroll to Contact
     
      { label: t("navigation:LOCATION"), href: "#location-section" },
      { label: "FAQ", href: "#faq-section" }, // Scroll to FAQ
      // { label: t("navigation:ABOUT_US"), href: "/" },
      // { label: t("navigation:CONTACT_US"), href: "/contact" },
      
    ];

    if (isMobile) {
      navLinks.push({ label: "CALL US", href: "tel:+1234567890" });
    }

    if (isLoggedIn()) {
      navLinks.push({ label: t("navigation:LOGOUT"), href: "/" });
    } else {
      navLinks.push({ label: t("navigation:LOGIN"), href: "/auth" });
    }

    return navLinks;
  };

  // const fetchCart = async () => {
  //   const { data, isSuccess, isError, error } = await getCart(true);
  //   if (isSuccess && data?.data) {
  //     setCartCount(data?.data?.cartItems?.length);
  //     dispatch(setCartCountState(data?.data?.cartItems?.length));
  //     dispatch(setCartListState(data?.data));
  //   }

  //   if (isError) {
  //     console.log(error);
  //     setCartCount(0);
  //   }
  // };

  const logoutHandler = () => {
    localStorage.removeItem("authToken");
    dispatch(resetCartState());
    dispatch(resetProductState());
    dispatch(resetCategoryState());
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


  const handleNavLinkClick = (navLink) => {
    if (navLink.label?.toLowerCase() === "logout") {
      logoutHandler();
      handleDrawerToggle();
    } else if (navLink.href.startsWith("tel:")) {
      window.location.href = navLink.href; // Handle call link
    } else if (navLink.href.startsWith("#")) {
      scrollToSection(navLink.href.substring(1)); // Handle internal links
      handleDrawerToggle();
    } else {
      navigate(navLink.href, { state: { fromDrawer: true } }); // Handle external navigation
    }
  };

  const renderMobileMenu = (
    <Drawer
      PaperProps={{ sx: { width: "65%" } }}
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerToggle}
    >
      <div className="drawer ">
        <div className="drawerCloseButton">
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ color: THEME_CONSTANT.COLOR.PRIMARY_WHITE }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div>
        <List>
          {getNavLinks(isMobile).map((navLink) => (
            <ListItemButton
              key={navLink.label}
              sx={{
                textAlign: "center",
                fontSize: THEME_CONSTANT.FONT_SIZE.NORMAL,
              }}
              onClick={() => handleNavLinkClick(navLink)}
            >
              <ListItemText
                className="list-item-container"
                primary={navLink.label}
              />
            </ListItemButton>
          ))}
        </List>
        </div>
      </div>
    </Drawer>
  );

  const renderDesktopMenu = (
    <div className="flex flex-row items-center w-full z-10">
      <div className="flex w-full pt-5" sx={{ flex: 1 }}>
        {/* <h1 className="text-[#FDCF72]">CALL US</h1> */}
        <a href="tel:+1234567890" className="hover:underline text-[#FDCF72] md:text-[14px] lg:text-[16px] ">
              CALL US
            </a>
      </div>
      <div onClick={() => navigate("/")} className="cursor-pointer">
        {/* Clickable logo to navigate to home */}
        <img src={logo} alt="logo" className="w-72 h-32 pt-10" />
      </div>
      <div className="flex justify-end w-full pt-5">
        {getNavLinks().map((navLink) => (
          <Typography
            key={navLink.label}
            variant="subtitle1"
            sx={{ mx: 2, color: "#FDCF72", cursor: "pointer" , fontSize: {
              md: "14px",  
              lg: "16px",  
            }, }}
            onClick={() => handleNavLinkClick(navLink)}
            
          >
            {navLink.label}
          </Typography>
        ))}
      </div>
    </div>
  );

  // ... (existing code remains unchanged)

  return (
    <>
      <AppBar
        position="relative"
        className="appBar"
        sx={{
          bgcolor: isThemeBackground ? "transparent" : "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar className="toolbar">
          {isMobile ? (
            <>
            <div className="flex justify-between w-full items-center z-[999]">
              <div className="mt-4">
            <LogoImage isMobile />
            {/* <Box sx={{ flexGrow: 1 }} />  */}
            </div>
            <div className="items-end mt-4">
              <MenuIcon onClick={handleDrawerToggle} />
              <Box sx={{ flexGrow: 1 }} /> {/* Add space to the left */}
              </div>
              {/* Add space to the right */}
              {/* <IconButton onClick={() => navigate("/cart")}>
                <Badge
                  badgeContent={cartCount}
                  classes={{ badge: "cartBadge" }}
                >
                  <ShoppingCartIcon
                    sx={{ color: THEME_CONSTANT.COLOR.SECONDARY_BLACK }}
                  />
                </Badge>
              </IconButton> */}
              </div>
            </>
          ) : (
            renderDesktopMenu
          )}
        </Toolbar>
      </AppBar>
      {isMobile && renderMobileMenu}
    </>
  );
};

export default Header;
