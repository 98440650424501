import React from "react";

import "./menu.css";

const MenuIcon = ({ onClick }) => {
  return (
    <div className="hamburger-menu " id="hamburger-menu" onClick={onClick}>
      <div className="menu-bar1"></div>
      <div className="menu-bar2"></div>
      <div className="menu-bar3"></div>
    </div>
  );
};

export default MenuIcon;
