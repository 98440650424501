import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  drawerCart: {},
  selectedProdctData: {},
  productListState: []
};

export const productSlice = createSlice({
  name: "product",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setProductListState: (state, action) => {
      console.log(action);
      state.productListState = [ ...action.payload ];
    },
    setSelectedProductData: (state, action) => {
      console.log(action);
      state.selectedProdctData = { ...action.payload };
    },
    setDrawerCart: (state, action) => {
      console.log(action);
      state.drawerCart = { ...action.payload };
    },
    resetProductState: () => initialState,
  },
});

export const { setDrawerCart, setSelectedProductData, setProductListState, resetProductState } =
  productSlice.actions;

export default productSlice.reducer;
