import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  name: "",
  currentUser: {
    country_flag: "🇦🇺",
    country_code: "+61",
  },
  currentUserSession: {},
  cpoDetails: {},
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateCurrentSession: (
      state,
      action
    ) => {
      state.currentUserSession = {
        ...state.currentUserSession,
        ...action.payload,
      };
    },
    updateCurrentUser: (state, action) => {
      state.currentUser = { ...state.currentUser, ...action.payload };
    },
    updateCpoDetails: (state, action) => {
      state.cpoDetails = { ...state.cpoDetails, ...action.payload };
    },
    resetUserState: () => initialState,
  },
});

export const {
  updateCurrentUser,
  updateCurrentSession,
  updateCpoDetails,
  resetUserState,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state) => state.user;

export default userSlice.reducer;
