import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { TheLayout } from "./containers";
import "./App.css";
import { FallbackLoader } from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loading = <FallbackLoader />;

// Containers
// const TheLayout = React.lazy(() => import("./containers"));

//views
const theme = createTheme();

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={loading} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Routes>
                  {/* <Route
                  path="/create-account"
                  name="Create Account"
                  render={(props) => <CreateAccount {...props} />}
                />
                <Route
                  path="/login"
                  name="Log In"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  path="/forgot-password"
                  name="Forgot Password"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  path="/reset/:resetToken"
                  name="Reset Password"
                  render={(props) => <ResetPassword {...props} />}
                /> */}
                  <Route path="/*" element={<TheLayout />} />
                  {/* <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                /> */}
                </Routes>
              </React.Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
