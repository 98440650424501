import React from "react";
import logo from "../../assets/images/Soul_pizza_logo.png";
import "./logo-image.css";
import { useNavigate } from "react-router";

const LogoImage = ({ isMobile }) => {
  const navigate = useNavigate();
  return (
    <div className={isMobile ? "image-container" : "website-image-container"}>
      <img
        src={logo}
        alt="logo"
        className={isMobile ? "image" : "website-image"}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default LogoImage;
