import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "./locales/en/common.json";
import navigationEN from "./locales/en/navigation.json";
import categoriesEN from "./locales/en/categories.json";
import bannerEN from "./locales/en/banner.json";
import cartEN from "./locales/en/cart.json";
import authEN from "./locales/en/auth.json";

const resources = {
  en: {
    common: commonEN,
    navigation: navigationEN,
    categories: categoriesEN,
    banner: bannerEN,
    cart: cartEN,
    auth: authEN
  },
};

// i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const t = i18n.t.bind(i18n);
export default i18n;
