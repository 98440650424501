import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  cartCountState: 0,
  cartListState: {},
  cartDetails: null,
};

export const cartSlice = createSlice({
  name: "cart",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCartCountState: (state, action) => {
      state.cartCountState = action.payload;
    },
    setCartListState: (state, action) => {
      state.cartListState = { ...action.payload };
    },
    resetCartState: () => initialState,
    setCartDetails: (state, action) => {
      state.cartDetails = { ...action.payload };
    },
  },
});

export const {
  setCartCountState,
  setCartListState,
  resetCartState,
  setCartDetails,
} = cartSlice.actions;

export default cartSlice.reducer;
