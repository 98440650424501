import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../routes";
import { FallbackLoader } from "../components/Loader";
import { isLoggedIn } from "../lib/helperFunctions";

const loading = <FallbackLoader />;

function Protected({ isProtected, children }) {
  if (isProtected && !isLoggedIn()) {
    return <Navigate to="/auth" />;
  }
  return children;
}

const TheContent = () => {
  return (
    <main>
      <Suspense fallback={loading}>
        <Routes>
            <React.Fragment>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  // exact={route.exact}
                  name={route.name}
                  element={<Protected isProtected={route.protected}>{<route.component />}</Protected>}
                //   render={(props) =>
                //     // localStorage.getItem("authToken") !== null ? (
                //       <route.component {...props} />
                //     // ) : (
                //     //   <Navigate
                //     //     to={{
                //     //       pathname: "/login",
                //     //       state: { from: props.location },
                //     //     }}
                //     //   />
                //     // )
                //   }
                />
              )
            );
          })}
          </React.Fragment>
          {/* <Navigate from="/" to="/home" /> */}
        </Routes>
      </Suspense>
    </main>
  );
};

export default TheContent;
