// import React from "react";
// import { useTranslation } from "react-i18next";
// import MenuList from "../../assets/images/menu-list.png";
// import MenuDownload from "../../assets/images/menu-download.png";

// const Menus = () => {
//   const { t } = useTranslation();

//   return (
//     <div className="flex min-h-screen bg-[#dda128]">
//       <div className="flex flex-row w-full relative">
//         <div className="w-full flex justify-end items-end">
//           <div
//             className="bg-no-repeat bg-contain bg-bottom pt-5 pl-5 pr-5 w-full h-5/6"
//             style={{
//               backgroundImage: `url(${MenuList})`,
//             }}
//           />
//           <div
//             className="absolute bg-no-repeat bg-contain w-full bg-bottom pt-5 pl-5 pr-5 h-1/2"
//             style={{
//               backgroundImage: `url(${MenuDownload})`,
//             }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Menus;
import React from "react";
import { useTranslation } from "react-i18next";
import MenuList from "../../assets/images/menu-list.png";
import MenuDownload from "../../assets/images/menu-download.png";
import SamplePDF from "../../assets/images/soul-pizza-Menu.pdf"; 

const Menus = () => {
  const { t } = useTranslation();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = SamplePDF;
    link.download = "Menu.pdf"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex min-h-72 sm:min-h-96 lg:min-h-screen bg-[#FFECC6] px-6 md:px-0">
      <div className="flex flex-row w-full relative ">
        <div className="w-full flex justify-end items-end">
          <div
            className="bg-no-repeat bg-contain bg-bottom pt-5 pl-5 pr-5 w-full h-5/6 "
            style={{
              backgroundImage: `url(${MenuList})`,
            }}
          />
          <div
            className="absolute bg-no-repeat bg-contain w-full bg-bottom pt-5 pl-5 pr-5 h-1/2 cursor-pointer"
            style={{
              backgroundImage: `url(${MenuDownload})`,
            }}
            onClick={handleDownload} 
          />
          <div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menus;
