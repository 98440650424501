import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { baseApi } from "../services/base.service";

import chargerReducer from "./slices/charger";
import userReducer from "./slices/user";
import categoryReducer from "./slices/category";
import productsReducer from "./slices/products";
import cartReducer from "./slices/cart";
import addressReducer from "./slices/address";

const persistConfig = {
  key: "root",
  version: 1,
  // storage: AsyncStorage,
  storage: storage,
  whitelist: ["user"],
  blacklist: ["baseApiReducer"], // add those reducer that do not need to be persisted
};

const reducer = combineReducers({
  user: userReducer,
  charger: chargerReducer,
  category: categoryReducer,
  product: productsReducer,
  cart: cartReducer,
  address: addressReducer,
  // Added API generated reducer
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
