import React from "react";

const HomePage = React.lazy(() => import("./views/HomePage"));
const Categories = React.lazy(() => import("./views/Categories"));
const ContactUS = React.lazy(() => import("./views/ContactUs"));
const Products = React.lazy(() => import("./views/Products"));
const ShoppingCart = React.lazy(() => import("./views/ShoppingCart"));
const Auth = React.lazy(() => import("./views/Auth"));
const OrderPlaced = React.lazy(() => import("./views/OrderPlaced"));
const TermsAndConditions = React.lazy(()=> import("./views/Terms"))
// const SignUp = React.lazy(() => import("./views/SignUp"));

const routes = [
  {
    path: "/order-paced",
    exact: true,
    name: "OrderPlaced",
    component: OrderPlaced,
    protected: true,
  },
  {
    path: "/cart",
    exact: true,
    name: "Cart",
    component: ShoppingCart,
    protected: true,
  },
  {
    path: "/categories",
    exact: true,
    name: "Categories",
    component: Categories,
    protected: false,
  },
  {
    path: "/contact",
    exact: true,
    name: "ContactUS",
    component: ContactUS,
    protected: false,
  },
  {
    path: "/products",
    exact: true,
    name: "Products",
    component: Products,
    protected: false,
  },
  {
    path: "/",
    exact: true,
    name: "Home",
    component: HomePage,
    protected: false,
  },
  {
    path: "/auth",
    exact: true,
    name: "Auth",
    component: Auth,
    protected: false,
  },
  {
    path: "/terms",
    exact: true,
    name: "Terms",
    component: TermsAndConditions,
    protected: false,
  },
  // {
  //   path: "/sign-up",
  //   exact: true,
  //   name: "SignUp",
  //   component: SignUp,
  // },
];

export default routes;
