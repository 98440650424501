import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  selectedStoreState: {},
  selectedCategoryState: {},
  cityListState: [],
  storeListState: [],
  categoryListState: [],
  selectedCityState: "",
  selectedStoreStateID: 0
};

export const categorySlice = createSlice({
  name: "category",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStoreSelected: (state, action) => {
      state.selectedStoreState = { ...action.payload };
    },
    setCategorySelected: (state, action) => {
      state.selectedCategoryState = { ...action.payload };
    },
    setCategoryCityList: (state, action) => {
      state.cityListState = [ ...action.payload ];
    },
    setCategoryStoreList: (state, action) => {
      state.storeListState = [ ...action.payload ];
    },
    setCategoryListState: (state, action) => {
      state.categoryListState = [ ...action.payload ];
    },
    setSelectedCityState: (state, action) => {
      state.selectedCityState = action.payload;
    },
    setSelectedStoreState: (state, action) => {
      state.selectedStoreStateID = action.payload;
    },
    resetCategoryState: () => initialState,
  },
});

export const { setStoreSelected, setCategorySelected, resetCategoryState, setCategoryCityList, setCategoryStoreList, setCategoryListState, setSelectedCityState, setSelectedStoreState } =
  categorySlice.actions;

export default categorySlice.reducer;
