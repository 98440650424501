import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
    serialNumber: "",
    chargingSession: {
        charger_sn: '',
        chargePointDetails: {},
        evDetails: {
            id: "1",
            manufacturer: "Hyundai",
            model: "Ioniq 5",
            range: 507,
            batterySize: 77.4,
            picture: ['https://ev-database.org/img/auto/Hyundai_IONIQ5_2021/Hyundai_IONIQ5_2021-01@2x.jpg'],
        },
        connectorDetails: {},
        chargingDetails: {},
        maxChargeMinutes: 0,
        maxChargePercent: 0,
        startTime: '',
        stopTime: '',
        sessionId: '',
        batteryPercent: 10,
        pricePerKWh: 0.5
    },
};

export const chargerSlice = createSlice({
    name: "charger",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`

        updateSerialNumber: (state, action) => {
            console.log('=====reducer' + action.payload + '========')
            state.serialNumber = action.payload;
        },
        updateChargingSession: (state, action) => {
            state.chargingSession = { ...state.chargingSession, ...action.payload }
        },
        resetChargingSession: (state) => {
            state.chargingSession = initialState.chargingSession;
        }
    },
});

export const { updateSerialNumber, updateChargingSession, resetChargingSession } = chargerSlice.actions;

export default chargerSlice.reducer;
