import React from "react";
import { Typography } from "@mui/material";

const SectionTitle = ({ title }) => (
  <Typography
    sx={{ fontSize: "1.3em", marginTop: "20px", marginBottom: "5px" }}
  >
    {title}
  </Typography>
);

export default SectionTitle;
