import React from "react";
import { TheContent } from "../index";

const TheLayout = () => {
  return (
    <div className="">
      <TheContent />
    </div>
  );
};

export default TheLayout;
