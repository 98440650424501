import * as moment from "moment";
import { THEME_CONSTANT } from "./themeConstants";
import _ from "lodash";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getBadge = (status) => {
  switch (status) {
    case "Accepted":
      return "green";
    case "Pending":
      return "orange";
    case "Rejected":
      return "red";
    case "Removed":
      return "red";
    default:
      return "orange";
  }
};

export const formateDate = () => {
  return moment().format("DD MMM YYYY h:mm:ss A");
};

export const formateDateArg = (date) => {
  return moment(date).format("DD MMM YYYY h:mm:ss A");
};

export const systemDateToCustom = (systemDate) => {
  return moment(systemDate).format("DD MMM YYYY");
};

export const toIndianDateFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const customDateToSystem = (formatedDate, splitValue = " ") => {
  let dateArray = formatedDate.split(splitValue);
  let date = dateArray[0];
  let month = dateArray[1];
  let year = dateArray[2];
  return year + "-" + (months.indexOf(month) + 1) + "-" + date;
};

export const formateDueDate = (date) => {
  let givenDate = moment(date);
  let todayDate = moment();

  let formatedDate = `Due on ${moment(date).format("DD MMM YYYY")}`;
  if (!date) {
    return "";
  } else if (givenDate.isSame(moment().subtract(1, "day"), "day")) {
    return "Due yesterday";
  } else if (givenDate.isSame(moment().add(1, "day"), "day")) {
    return "Due tomorrow";
  } else if (givenDate.isSame(todayDate, "day")) {
    return "Due today";
  } else if (givenDate.isAfter(todayDate)) {
    return formatedDate;
  } else if (givenDate.isBefore(todayDate)) {
    return formatedDate;
  }
};

export const isExpiredDate = (date) => {
  if (moment(date).isBefore(moment())) {
    return true;
  } else {
    return false;
  }
};

export const addFilesList = (files, newFileList) => {
  const tempArray = [...files];
  let count = tempArray.length;
  for (let i = 0; i < newFileList.length; i++) {
    const tempObj = {
      id: count,
      name: newFileList[i].name,
      dateTime: formateDate(),
      size: `${Math.ceil(newFileList[i].size / 1000)} kb`,
      type: newFileList[i].type,
    };
    tempArray.push(tempObj);
    count++;
  }

  // setFiles(tempArray);
  return tempArray;
};

export const getErrorMsg = (error) => {
  if (typeof error === "string") {
    return error;
  } else {
    return error[0];
  }
};

export const progressColorHelper = (progress) => {
  let progressColors = THEME_CONSTANT.progressColors;
  let color;
  if (progress) {
    _.forOwn(progressColors, (value, key) => {
      if (key.toLocaleLowerCase().includes(progress.toLocaleLowerCase())) {
        color = value;
      }
      return;
    });
  } else {
    color = THEME_CONSTANT.progressColors.COMPLETED;
  }
  return color;
};

export const priorityColorHelper = (priority) => {
  let periorityColors = THEME_CONSTANT.priorityColors;
  let color;
  if (!priority) return "";
  _.forOwn(periorityColors, (value, key) => {
    if (key.toLocaleLowerCase().includes(priority.toLocaleLowerCase())) {
      color = value;
    }
    return;
  });
  return color;
};

export const isQuillEmpty = (value) => {
  if (
    value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
    !value.includes("<img")
  ) {
    return true;
  }
  return false;
};

export const getCountryFromURL = () => {
  const segments = window.location.hostname.split(".");
  return segments.pop();
  // return "nz";
};

export const updateObjectListById = (arr, id, propertyToUpdate, valueToUpdate) => {
  return arr.map((obj) => {
    if (obj.id === id) {
      return {
        ...obj,
        [propertyToUpdate]: valueToUpdate,
      };
    }
    return obj;
  });
};

export const isLoggedIn = () => {
  const token = localStorage.getItem("authToken");
  return token ? true : false;
}
