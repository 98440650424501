import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  selectedAddressData: {},
  addressListState: [],
};

export const addressSlice = createSlice({
  name: "address",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAddressListState: (state, action) => {
      state.addressListState = [...action.payload];
    },
    setSelectedAddressData: (state, action) => {
      state.selectedAddressData = { ...action.payload };
    },
    resetAddressState: () => initialState,
  },
});

export const {
  setAddressListState,
  setSelectedAddressData,
  resetAddressState,
} = addressSlice.actions;

export default addressSlice.reducer;
