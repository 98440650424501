// import React from "react";
// import { useTranslation } from "react-i18next";
// import {
//   IconButton,
//   Typography,
//   Box,
//   Grid,
//   Container,
//   Button,
//   Link,
// } from "@mui/material";

// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import logo from "../../assets/images/Soul_pizza_logo_white.png";
// import "./footer.css";

// const Footer = () => {
//   const { t } = useTranslation();
//   return (
//     <Box className="root">
//       <Container maxWidth="lg">
//         <Grid container alignItems="center" spacing={2}>
//           <Grid item xs={12} sm={4} md={3}>
//             <Box>
//               <img src={logo} alt="logo" className="logo" />
//             </Box>
//             <Typography variant="h6" className="tagline">
//               {t("common:LOGO_TAGLINE")}
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={8} md={6}>
//             <Grid container justifyContent="center">
//               <IconButton aria-label="Facebook" className="icon">
//                 <FacebookIcon />
//               </IconButton>
//               <IconButton aria-label="Twitter" className="icon">
//                 <TwitterIcon />
//               </IconButton>
//               <IconButton aria-label="Instagram" className="icon">
//                 <InstagramIcon />
//               </IconButton>
//               <IconButton aria-label="LinkedIn" className="icon">
//                 <LinkedInIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} sm={12} md={3}>
//             <Grid container direction="column">
//               <Link href="#" className={`link MuiLink-root`}>
//                 {t("navigation:DOWNLOAD_MENU")}
//               </Link>
//               <Link href="#" className={`link MuiLink-root`}>
//                 {t("navigation:CAREERS")}
//               </Link>
//               <Link href="#" className={`link MuiLink-root`}>
//                 {t("navigation:LOCATION")}
//               </Link>
//               <Link href="#" className={`link MuiLink-root`}>
//                 {t("navigation:FAQ")}
//               </Link>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} sm={12} md={12}>
//             <Grid container justifyContent="center">
//               <Button variant="contained" className="button">
//                 {t("navigation:CONTACT_US")}
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>

//         <Box mt={3} textAlign="center">
//           <Typography variant="body2">
//             &copy; {new Date().getFullYear()} {t("common:SOUL_PIZZA")}.{" "}
//             {t("navigation:ALL_RIGHTS_RESERVED")}.
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   );
// };

// export default Footer;
import React from "react";
import logo from "../../assets/images/Soul_pizza_logo.png";
import leftPizzaImage from "../../assets/images/left_image_footer.png";
import rightPizzaImage from "../../assets/images/right_image_footer.png";
import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import LinkedIn from "../../assets/images/linkedin.png";

const Footer = () => {
  return (
    <footer className="relative bg-gray-800 text-white  pb-6 max-h-screen">
      {/* Left Pizza Image */}
     
 {/* <div className="absolute left-0 top-1/2 transform -translate-y-1/2 ">
        <img src={leftPizzaImage} alt="Left Pizza" className="h-40 w-30 md:h-60 md:w-46" />
      </div>
      {/* Right Pizza Image */}
      {/* <div className="absolute right-0 pt-4 top-1/3 transform -translate-y-3/5 ">
        <img src={rightPizzaImage} alt="Right Pizza" className="h-30 w-24 md:h-32 md:w-32" />
      </div> */}

      {/* Footer Content */}
      <div className=" mx-auto flex flex-col items-center justify-between h-full">
        {/* Top Half: Logo */}
        <div className="flex justify-center items-center h-1/2 py-6">
          <img src={logo}  alt="Soul Pizza Logo" className="h-auto w-32" />
        </div>

        {/* Divider Line */}
        <div className="border-t border-gray-200 w-full mb-6"></div>

        {/* Bottom Half: Social Media and Links */}
        <div className="text-center">
          <div className="flex justify-center mb-6 space-x-4">
            <a
              href="https://www.facebook.com/SoulpizzaNZ?mibextid=LQQJ4d"
              aria-label="Facebook"
              className="text-2xl mx-2"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className="h-8 w-8"
              />
            </a>
            {/* <a
              href="https://www.linkedin.com"
              aria-label="LinkedIn"
              className="text-2xl mx-2"
            >
              <img
                src={LinkedIn}
                alt="LinkedIn"
                className="h-8 w-8"
              />
            </a> */}
            <a
              href="https://www.instagram.com/soulpizzanz?igsh=MTI0OGpmYTRwdjVydg%3D%3D&utm_source=qr"
              aria-label="Instagram"
              className="text-2xl mx-2"
            >
              <img
               src={Instagram}
                alt="Instagram"
                className="h-8 w-8"
              />
            </a>
          </div>

          <div className="flex justify-center space-x-6">
            
            <a href="/categories" className="hover:underline">
              ORDER NOW
            </a>
            <a href="/terms" className="hover:underline">
              Terms & Condition
            </a>
            <a href="/" className="hover:underline">
            Allergen Card
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
