import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { THEME_CONSTANT } from '../../lib/themeConstants';

function FallbackLoader() {

  return (
    <>
      <Backdrop open={true} sx={{ backgroundColor: THEME_CONSTANT.COLOR.BODY_BACKGROUND }}>
        <CircularProgress sx={{ color: THEME_CONSTANT.COLOR.PRIMARY }} />
      </Backdrop>
    </>
  );
}

export default FallbackLoader;
