// import React, { useState } from "react";
// import "./FAQ.css";

// const FAQ = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleAccordion = (index) => {
//     if (activeIndex === index) {
//       setActiveIndex(null);
//     } else {
//       setActiveIndex(index);
//     }
//   };

//   const faqs = [
//     {
//       question: "What is Soul Pizza?",
//       answer:
//         "Soul Pizza is an food odering webiste where you can order your lovely food and enjoy!",
//     },
//     {
//       question: "How can I place an order?",
//       answer:
//         "You can place an order by just click in the ordernow button and just follow some steps for address and order will confirm",
//     },
//     {
//       question: "Do you offer free shipping?",
//       answer:
//         "Yes, we offer free shipping on all orders above $50 within the continental US.",
//     },
//     {
//       question: "What is your return policy?",
//       answer:
//         "Wrong food will returns you just have to contact our support team ",
//     },
//   ];

//   return (
//     <div className="bg-[#fad385] px-5 py-16 md:py-40">
//       <div className="max-w-4xl mx-auto  p-6 bg-white rounded-lg shadow-md ">
//         <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
//           Frequently Asked Questions
//         </h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <div key={index} className="border-b border-gray-300">
//               <button
//                 className="w-full text-left flex justify-between items-center py-4 text-lg font-[500] text-gray-700 focus:outline-none"
//                 onClick={() => toggleAccordion(index)}
//               >
//                 {faq.question}
//                 <span className="text-gray-500">
//                   {activeIndex === index ? "-" : "+"}
//                 </span>
//               </button>
//               {activeIndex === index && (
//                 <div className="py-4 text-gray-600 font-[300] font-[Sora]">
//                   <p>{faq.answer}</p>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQ;
import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqs = [
    {
      question: "What is the store's address?",
      answer:
        "The address is: 531 Sandringham Road, Sandringham, Auckland 1031.",
    },
    {
      question: "What are the store's operating hours?",
      answer: (
        <>
          - Monday: 4 PM - 11 PM
          <br />
          - Tuesday to Sunday: 11:30 AM - 11 PM
          <br />
          <strong className="font-[700]">(Except Friday & Saturday: open until 12 AM)</strong>
        </>
      ),
    },
    {
      question: "Are you Halal certified?",
      answer: "Yes, we are fully Halal certified.",
    },
    {
      question: "Do you offer gluten-free options?",
      answer:
        <>
        Yes, we do offer gluten-free options, depending on the availability of gluten-free bases from our supplier. Please note there is an additional charge for gluten-free bases. For more information, ask one of our staff members.
        <br />
        <strong className="font-[700]">
        Note: While we take the utmost care, our gluten-free products may still contain traces of gluten.
        </strong>
        </>
    },
    {
      question: "Do you provide delivery services?",
      answer:
        "Not at the moment, but we are planning to offer delivery soon.",
    },
  ];

  return (
    <div className="bg-[#ffdd98] px-5 py-16 md:py-40 relative">
      <img
    src={require("../../assets/images/new-deal.png")}
    alt="New Deal"
    className="new-deal-image"
  />
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md relative z-10">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-300">
              <button
                className="w-full text-left flex justify-between items-center py-4 text-lg font-medium text-gray-700 focus:outline-none font-[Sora]"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="text-gray-500">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              {activeIndex === index && (
                <div className="py-4 text-gray-600 font-[300] font-[Sora]">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="fog-effect absolute inset-0 z-0"></div>
    </div>
  );
};

export default FAQ;
