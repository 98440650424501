import React, { useState, useEffect, useRef } from "react";
import { Box, Container } from "@mui/material";

import { THEME_CONSTANT } from "../../lib/themeConstants";
import ScrollingBackground from "./components/ScrollingImage";
import "./banner.css";
import Certified from "../../assets/images/certified.png";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {
  const navigate = useNavigate();

 

  const messages = [
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "*", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
    { text: "ORDER NOW", fontClass: "" },
    { text: "ORDER NOW", fontClass: "font-thunder" },
  ];

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* <div className="relative">
        <div className="background-image h-screen flex items-center justify-center">
          <h1 className="text-4xl sm:text-6xl md:text-8xl lg:text-[120px] xl:text-[140px] text-center font-thunder text-[#FDCF72] ">
            YOU’ VE HAD THE REST
            <br /> NOW TRY THE BEST
          </h1>
        </div> */}
       
        <div className="background-image h-screen relative">
          <div>
        <div>
          <h1 className="text-5xl sm:text-6xl md:text-8xl lg:text-[120px] xl:text-[140px] text-center font-thunder text-[#FDCF72]">
          YOU’VE HAD THE REST
            <br /> NOW TRY THE BEST
          </h1>
          </div>
          <div className="halal-certification">
    <img src={Certified} alt="Certified Halal" />
  </div>
          {/* <button
              onClick={() => navigate("/categories")}
              className="bg-[#FDCF72] px-2 py-2 rounded-md text-sm sm:text-md md:text-lg lg:text-xl"
            >
             OrderNow
            </button> */}
          {/* <div className="flex justify-between mt-5">
          <button
              onClick={() => navigate("/categories")}
              className="bg-[#FDCF72] px-2 py-2 rounded-md text-sm sm:text-md md:text-lg lg:text-xl"
            >
             OrderNow
            </button>
            <button
              onClick={() => scrollToSection("location-section")}
              className="bg-[#FDCF72] px-2 py-2 rounded-md text-sm sm:text-md md:text-lg lg:text-xl"
            >
              Location
            </button>
          </div> */}
        </div>

        {/* <div className="flex justify-between mt-5">
          <button
              onClick={() => navigate("/categories")}
              className="bg-[#FDCF72] px-2 py-2 rounded-md text-sm sm:text-md md:text-lg lg:text-xl"
            >
             OrderNow
            </button>
          </div> */}

        <div className="absolute top-[90%] sm:top-[90%] md:top-[90%] lg:top-[91%] xl:bottom-0 w-full p-4 overflow-hidden z-10">
          <div className="horizontal-scroll whitespace-nowrap animate-horizontal-scroll">
            <div className="scroll-content">
              {messages.map((message, index) => (
                <h1
                  key={index}
                  className={`text-[#FDCF72] inline-block text-3xl sm:text-4xl ${message.fontClass} mr-2`}
                >
                  {message.text}
                </h1>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="fixed inset-0 flex justify-center top-52 z-20 ">
        <img
          src={pizza}
          alt="Pizza"
          className="w-[926px] h-[837px] top-[209px] left-[337px] "
        />
      </div> */}

      <div className="background-image2  md:h-screen relative">
        <Box className="flex items-center justify-center h-full pt-24 sm:pt-24 md:pt-48 lg:pt-32 xl:pt-36">
          <h1
            className="text-[160px] sm:text-[250px] md:text-[350px] lg:text-[450px] xl:text-[550px] text-center leading-[120px] text-black"
            style={{
              textShadow: `
        -2px -2px 0 #fff,  
        2px -2px 0 #fff,
        -2px 2px 0 #fff,
        2px 2px 0 #fff,
        -3px -3px 0 #fff,  
        3px -3px 0 #fff,
        -3px 3px 0 #fff,
        3px 3px 0 #fff
      `,
            }}
          >
            DEALS
          </h1>
        </Box>
        <div className="fog-effect absolute inset-0 z-0"></div>
      </div>
      
    </>
  );
};

export default Dashboard;
